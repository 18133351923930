import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledRanking = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  gap: 5px;
  position: relative;
  padding-top: 10%;
  padding-bottom: 25%;
  background: white;

  .background-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    figure {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        background-size: contain;
      }
    }
  }

  .logo-wrapper {
    svg {
      width: 120px;
    }
  }

  .ranking-wrapper {
    position: relative;
    height: 50%;
    width: 90%;
    max-width: 500px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: ${colors.alabaster};
    overflow: hidden;
    border-radius: 15px;

    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;

        & path {
          fill: ${colors.gallery};
        }

        & polygon {
          fill: ${colors.gallery};
        }
      }
    }

    &_loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item {
      height: 10%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: ${colors.black};
      flex-wrap: nowrap;
      overflow: hidden;
      z-index: 99;

      span {
        white-space: nowrap;
        font-family: 'DM', sans-serif;
        font-weight: 400;
        font-size: 1.8rem;

        &:first-child {
          max-width: 70%;
          overflow: hidden;
          margin-right: 15px;
        }
      }

      &.podium {
        span {
          font-size: 2rem;
          font-weight: 500;
        }

      }

    }
  }

  button {
    z-index: 1;
    font-family: 'DM', sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: ${colors.white};
    background: ${colors.black};
    padding: 10px 30px;
    margin-top: 30px;
    border-radius: 13px;

    &.large {
      font-size: 1.7rem;
      padding: 15px 50px;

    }

    &:hover {
      background: ${colors.crusta};
    }
  }
}
`;

export default StyledRanking;
