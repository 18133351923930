import React from 'react';
import {
  createBrowserRouter, RouterProvider, useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import Login from './pages/auth/Login';
import RequestPassword from './pages/auth/RequestPassword';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/auth/ResetPassword';
import SignUp from './pages/auth/SignUp';
import axios from './utils/axios';
import MyQrCode from './pages/MyQrCode';
import Apps from './pages/QrGenerator';
import Stand from './pages/Stand';
import QrScanner from './pages/QrScanner';
import Ranking from './pages/Ranking';
import TvRanking from './pages/TvRanking';

const connected = async () => {
  const localUserString = localStorage.getItem('user') || false;
  const user = JSON.parse(localUserString);
  if (user?.firstname && user?.lastname && user?.group) {
    return user;
  }
  throw new Response('', {
    status: 401,
    statusText: 'Not Authorized',
  });
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/my-qr-code',
    element: <MyQrCode/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/qr-generator',
    element: <Apps/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/qr-stands',
    element: <Apps/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/quizz/:quizzId',
    element: <Stand/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/stand/:standId',
    element: <Stand/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/qr-scanner',
    element: <QrScanner/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/ranking',
    element: <Ranking/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/tv-ranking',
    element: <TvRanking/>,
    errorElement: <NotFound/>,
    loader: connected,
  },
  {
    path: '/login',
    element: <Login/>,
    errorElement: <NotFound/>,
  },
  {
    path: '/sign-up',
    element: <SignUp/>,
    errorElement: <NotFound/>,
  },
  {
    path: '/request-password',
    element: <RequestPassword/>,
    errorElement: <NotFound/>,
  },
  {
    path: '/reset-password',
    element: <ResetPassword/>,
    errorElement: <NotFound/>,
  },
]);

const Router = () => (
  <RouterProvider router={router}/>
);

export default Router;
