import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledStand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 100vh;
  width: 100%;
  padding: 0 20px 20%;
  background: ${(props) => props?.primaryColor || 'unset'};

  h1 {
    font-family: 'DM', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 2rem;
    color: ${colors.white};
  }

  .logo-wrapper {
    svg {
      width: 120px;
    }
  }

  .background-wrapper {
    position: fixed;
    left: -1px;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    figure {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        background-size: contain;
      }
    }
  }

  .result-wrapper {
    z-index: 10;
    width: 100%;
    min-height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 10px;

    span {
      color: ${colors.black};
      font-family: 'DM', sans-serif;
      font-weight: 700;
      font-size: 3.5rem;
      text-transform: uppercase;

      &:first-child {
        color: ${colors.white};
      }

      &:last-child {
        background: ${colors.mercury};
        font-size: 4rem;
        padding: 15px;
        border-radius: 25px;
        background: #E5E5E5;
        transform: rotate(-2deg);
      }
    }
  }

  .content {
    margin-top: 50px;
    z-index: 10;
    width: 100%;
    min-height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    background: ${(props) => props?.primaryColor || 'unset'};

    form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
      width: 100%;

      .radio-wrapper {
        box-shadow: 0 0 1px 1px ${colors.black};
        padding: 15px;
        background: ${colors.white};
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        cursor: pointer;

        input {
          display: none;
        }

        span {
          font-family: 'DM', sans-serif;
          font-weight: 500;
          font-size: 1.6rem;
          text-align: center;
          color: ${colors.black};
        }

        &.active {
          background: ${(props) => props?.primaryColor};
          box-shadow: 0 0 5px 2px ${colors.white};

          span {
            color: ${colors.white};
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    button {
      z-index: 1;
      font-family: 'DM', sans-serif;
      font-weight: 500;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: ${colors.white};
      background: ${colors.black};
      padding: 10px 30px;
      margin-top: 30px;
      border-radius: 13px;

      &.large {
        font-size: 1.7rem;
        padding: 15px 50px;

      }

      &:hover {
        background: ${colors.manatee30};
      }

      &.inverse {
        color: ${colors.black};
        background: ${colors.white};
      }
    }

  }
`;

export default StyledStand;
