import { createGlobalStyle } from 'styled-components';
import { colors } from './globals';

// Fonts
import DMBoldttf from '../fonts/DMSans-Bold.ttf';
import DMExtraBoldttf from '../fonts/DMSans-ExtraBold.ttf';
import DMttf from '../fonts/DMSans-Regular.ttf';
import DMMediumttf from '../fonts/DMSans-Medium.ttf';
import DMSemiBoldttf from '../fonts/DMSans-SemiBold.ttf';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.athens};
    background-repeat: no-repeat;
    background-position: center bottom -150px;
    color: ${colors.manatee};
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.2;
    width: 100%;
    height: 100%;
  }

  @font-face {
    font-family: 'DM';
    src: url(${DMttf}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM';
    src: url(${DMMediumttf}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM';
    src: url(${DMSemiBoldttf}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM';
    src: url(${DMBoldttf}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM';
    src: url(${DMExtraBoldttf}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }
`;

export default GlobalStyle;
