import styled from 'styled-components';
import { colors } from '../../utils/globals';

const StyledQrCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  h1 {
    font-size: 3rem;
  }
  span {
    color: ${colors.white};
  }

  .result-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    svg {
      width: 50px;
      height: 50px;
      fill: #2ed573;
    }
    span {
      font-size: 2rem;
      font-weight: 700;
      &:first-child {
        font-weight: 700;
      }
    }
    
    &.error {
      svg {
        fill: #ff4757;
      }
    }
  }

  button {
    padding: 5px 15px;
    color: ${colors.white};
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid ${colors.white};
    border-radius: 5px;

    &:hover {
      background: ${colors.crusta};
    }
  }

`;

export default StyledQrCode;
