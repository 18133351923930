import React, { useEffect } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledHome } from './style';
import Spinner from '../../component/Spinner';
import { colors } from '../../utils/globals';
import { ReactComponent as Logo } from '../../svg/mentionTB_logo.svg';

const Home = () => {
  const navigate = useNavigate();
  const user = useLoaderData();
  const logOut = () => {
    localStorage.clear();
    navigate('/login');
    toast.success('à bientôt !');
  };

  const toMyQrCode = () => {
    navigate('/my-qr-code');
  };

  const toQrScanner = () => {
    navigate('/qr-scanner');
  };

  const toQrGenerator = () => {
    navigate('/qr-generator');
  };

  const toRanking = () => {
    navigate('/ranking');
  };

  const toTvRanking = () => {
    navigate('/tv-ranking');
  };

  useEffect(() => {
    document.body.style.zoom = '100%';
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <StyledHome>
      <div className="background-wrapper">
        <figure>
          <img src="/mentionTB_fond-1.png" alt="bg"/>
        </figure>
      </div>
      <div className="logo-wrapper">
        <Logo/>
      </div>
      {
        user ? (
          <>
            <button
              type="button"
              className="large"
              onClick={() => toQrScanner()}
            >
              Scanner un QR Code
            </button>

            {
              user?.firstname === 'sevanova' && user?.lastname === 'sevanova' && (

                <button
                  type="button"
                  className="large"
                  onClick={() => toTvRanking()}
                >
                  Classement TV
                </button>

              )
            }

            <button
              type="button"
              className="large"
              onClick={() => toRanking()}
            >
              Classement
            </button>

            {
              user?.firstname === 'sevanova' && user?.lastname === 'sevanova' && (

                <button
                  type="button"
                  className="large"
                  onClick={() => toQrGenerator()}
                >
                  Qr Codes
                </button>

              )
            }

            <button
              type="button"
              onClick={() => logOut()}
            >
              Déconnexion
            </button>
          </>
        ) : <Spinner color={colors.blaze}/>
      }
      <Outlet/>
    </StyledHome>
  );
};

export default Home;
