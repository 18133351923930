import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from '../../utils/axios';
import { StyledMyQrCode } from './style';
import Spinner from '../../component/Spinner';
import { colors } from '../../utils/globals';

const MyQrCode = () => {
  const navigate = useNavigate();
  const user = useLoaderData();
  const backToHome = () => {
    navigate('/');
  };
  return (
    <StyledMyQrCode>
      {
        user ? (
          <>
            <span>Votre QR CODE</span>
            {
              user && (
                <QRCode
                  value={`${process.env.REACT_APP_BACK_URL}/users/meta/${_.get(user, '_id', '')}`}
                  size={250}
                />
              )
            }
            <button
              type="button"
              onClick={() => backToHome()}
            >
              Retour
            </button>
          </>
        ) : <Spinner color={colors.blaze} />
      }
      <Outlet />
    </StyledMyQrCode>
  );
};

export default MyQrCode;
