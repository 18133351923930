import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import StyledSelect from './style';

const CustomSelect = ({
                        label,
                        name,
                        required,
                        errors,
                        options,
                        placeholder,
                        control,
                      }) => {
  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    console.log('errors', errors);
  }, [errors]);

  return (
    <StyledSelect>
      <label>{label}</label>

      {!selectValue && (
        <Controller
          name={name}
          control={control}
          render={({field: {onChange, value, ref}}) => (
            <Select
              noOptionsMessage={() => ''}
              unstyled
              className="react-select-container"
              classNamePrefix="react-select"
              options={options}
              placeholder={placeholder}
              value={options.find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
            />
          )}
          rules={{required}}
        />
      )}
      {selectValue && (
        <Controller
          name={name}
          control={control}
          render={({field: {onChange, value, ref}}) => (
            <Select
              noOptionsMessage={() => ''}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder={placeholder}
              options={options}
              value={options.find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
              defaultValue={options.find((c) => c.value === selectValue)}
            />
          )}
          rules={{required}}
        />
      )}
      {errors[name] && <p className="error">Ce champs est obligatoire</p>}
    </StyledSelect>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  control: PropTypes.any.isRequired,
};

export default CustomSelect;
