import Axios, { InternalAxiosRequestConfig, AxiosInstance } from 'axios';

/**
 * Create Axios instance with api baseURL from env vars
 */
const { REACT_APP_BACK_URL } = process.env;
const axios = Axios.create({
  baseURL: `${REACT_APP_BACK_URL}`,
});

axios.interceptors.request.use((config) => {
  if (localStorage.getItem('user') && localStorage.getItem('access_token')) {
    const token = localStorage.getItem('access_token');
    config.headers.set('Authorization', `Bearer ${JSON.parse(token || '')}`);
  }
  return config;
},
(error) => Promise.reject(error));

export default axios;
