import styled from 'styled-components';
import { colors } from '../../utils/globals';

const StyledSelect = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;

  label {
    width: 80px;
    font-size: 1.6rem;
    color: ${colors.black};
    font-weight: 500;
  }

  .react-select {
    &-container {
      width: calc(100% - 80px);
      z-index: 99;
      padding: 0;
      font-family: 'DM', sans-serif;
      font-weight: 400;
      font-size: 1.6rem;
    }

    &__menu {
      &-list {
        border-radius: 12px;
        margin-top: 10px;
        background: ${colors.white};
        box-shadow: 0px 0px 20px -12px ${colors.limedSpruce};
      }
    }

    &__option {
      cursor: pointer;
      padding: 10px;
      color: ${colors.limedSpruce};
      font-family: 'DM', sans-serif;
      font-weight: 400;
      font-size: 1.3rem;

      &:hover {
        background: ${colors.athens};

      }
    }

    &__value-container {
      color: ${colors.limedSpruce};
      padding: 10px 0;
      border-bottom: 1px solid;
    }
  }

  .error {
    position: absolute;
    bottom: -10px;
    left: 80px;
    transform: translateY(100%);
    color: ${colors.cinnabar};
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

export default StyledSelect;
