import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from '../../utils/axios';
import { StyledQrScanner } from './style';
import Spinner from '../../component/Spinner';
import { colors } from '../../utils/globals';
import QrCodeReader from '../../component/QrCodeReader';
import { ReactComponent as Logo } from '../../svg/mentionTB_logo.svg';

const QrScanner = () => {
  const navigate = useNavigate();
  const user = useLoaderData();
  const backToHome = () => {
    navigate('/');
  };
  return (
    <StyledQrScanner>
      <div className="background-wrapper">
        <figure>
          <img src="/mentionTB_fond-2.png" alt="bg"/>
        </figure>
      </div>
      <div className="logo-wrapper">
        <Logo/>
      </div>
      {
        user ? (
          <div className="scanner-wrapper">
            <QrCodeReader/>
          </div>
        ) : <Spinner color={colors.mercury}/>
      }
      <button
        type="button"
        onClick={() => backToHome()}
      >
        Retour
      </button>
      <Outlet/>
    </StyledQrScanner>
  );
};

export default QrScanner;
