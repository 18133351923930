import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.blaze};
`;

export default StyledNotFound;
