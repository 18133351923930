import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import QRCode from 'qrcode.react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { StyledQrGenerator } from './style';
import Spinner from '../../component/Spinner';
import { colors } from '../../utils/globals';
// import allQuizz from '../../utils/allQuizz_loc.json';
import allQuizz from '../../utils/allQuizz.json';
import axios from '../../utils/axios';

const QrGenerator = () => {
  const navigate = useNavigate();
  const user = useLoaderData();
  const [apps, setApps] = useState(allQuizz);
  const [selectedApps, setSelectedApps] = useState();
  const [primaryColor, setPrimaryColor] = useState();
  const backToHome = () => {
    navigate('/');
  };
  useEffect(() => {
    setPrimaryColor(apps[selectedApps]?.primaryColor);
  }, [selectedApps]);

  const resetQuizz = async () => {
    await axios.post('/apps/signup/reset', {
      app: allQuizz[selectedApps]?.id,
    });
    toast.success('Les données de ce stand ont été supprimées avec succès');
  };
  return (
    <StyledQrGenerator>
      {
        apps ? (
          <>
            <h1
              className={selectedApps ? 'active' : ''}
            >
              {selectedApps ? apps[selectedApps]?.title : 'Qr Générateur'}
            </h1>
            {
              !selectedApps && (
                <div className="apps-wrapper">
                  {
                    Object?.keys(apps).map((key) => (
                      <div
                        key={key?.id}
                        className={'item'}
                        onClick={() => setSelectedApps(key)}
                        style={{
                          background: _.get(apps, [key, 'primaryColor'], ''),
                        }}
                      >
                        <span>{_.get(apps, [key, 'title'], '')}</span>
                      </div>
                    ))
                  }
                  {
                    Object?.keys(apps).length % 2 !== 0 && (<div className={'item ghost'}/>)
                  }
                </div>
              )
            }

            {
              selectedApps && (
                <div className="qr-wrapper">
                  <span>QrCode Pour obtenir des points</span>
                  <QRCode
                    value={`/stand/${selectedApps}`}
                    size={150}
                  />
                  <span>QrCode du Quizz</span>
                  <QRCode
                    value={`/quizz/${selectedApps}`}
                    size={150}
                  />
                  <span>{selectedApps}</span>
                </div>

              )
            }

            <div className="footer-wrapper">
              {
                selectedApps && (
                  <button
                    type="button"
                    onClick={() => resetQuizz()}
                  >
                    Réinitialiser
                  </button>
                )
              }
              <button
                type="button"
                onClick={() => {
                  if (selectedApps) {
                    setSelectedApps(null);
                  } else {
                    backToHome();
                  }
                }}
              >
                Retour
              </button>
            </div>

          </>
        ) : <Spinner color={colors.blaze}/>
      }
      <Outlet/>
    </StyledQrGenerator>
  );
};

export default QrGenerator;
