import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from './styled';

const Input = ({
                 label,
                 type = 'text',
                 name,
                 errors,
                 register,
                 required,
                 placeholder = '',
               }) => (
  <StyledInput>
    <label htmlFor={name}>{label}</label>
    <input
      placeholder={placeholder}
      type={type}
      id={name}
      {...register(name, {required})}
    />
    {errors[name] && <p className="error">Ce champs est obligatoire</p>}
  </StyledInput>
);

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.any.isRequired,
  register: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default Input;
