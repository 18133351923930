import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledQrScanner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  gap: 5px;
  position: relative;
  padding: 10% 0;
  background: white;

  .background-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    figure {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        background-size: contain;
      }
    }
  }

  .logo-wrapper {
    svg {
      width: 120px;
    }
  }

  .scanner-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 50%;
    overflow: hidden;
  }

  button {
    z-index: 1;
    text-transform: uppercase;
    font-size: 1.3rem;
    color: ${colors.white};
    background: ${colors.black};
    padding: 10px 30px;
    margin-top: 30px;
    border-radius: 13px;

    &.large {
      font-size: 1.7rem;
      padding: 15px 50px;

    }

    &:hover {
      background: ${colors.crusta};
    }
  }
}


`;

export default StyledQrScanner;
