import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import axios from '../../../utils/axios';
import { StyledResetPassword } from './style';
import Spinner from '../../../component/Spinner';

const ResetPassword = () => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('email');
  const [isLoading, setIsLoading] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const onSubmit = async (data = {}) => {
    setIsLoading(true);
    try {
      await axios.post('/password-reset', { ...data, token, email });
      toast.success('Mot de passe modifié');
      navigate('/login');
    } catch (error) {
      toast.error('Email incorrecte !');
      console.error(error);
      reset();
      setIsLoading(false);
    }
  };

  return (
    <StyledResetPassword>
      <h1>Nouveau mot de passe</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-wrapper">
          <label htmlFor="password">Nouveau mot de passe *</label>
          <input
            type="password"
            id="password"
            {...register('password', { required: true })}
          />
          {errors.password && <p className="error">Ce champs est obligatoire</p>}
        </div>
        <div className="input-wrapper">
          <label htmlFor="password">Confirmation *</label>
          <input
            type="password"
            id="passwordVerification"
            {...register('passwordVerification', { required: true })}
          />
          {errors.passwordVerification && <p className="error">Ce champs est obligatoire</p>}
        </div>
        {
          !isLoading ? (<button type="submit">Enregistrer</button>) : (<Spinner />)
        }
        <Link to={'/login'}>Annuler</Link>
      </form>
    </StyledResetPassword>
  );
};
export default ResetPassword;
