import styled from 'styled-components';
import { colors } from '../../utils/globals';

const StyledInput = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  position: relative;

  label {
    font-family: 'DM', sans-serif;
    font-weight: 500;
    width: 80px;
    font-size: 1.6rem;
    color: ${colors.black};
  }

  input {
    background: transparent;
    height: 100%;
    width: calc(100% - 80px);
    padding: 10px 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid;
    color: ${colors.limedSpruce};
    font-family: 'DM', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;

    &:focus {
      outline: none;
    }
  }

  .error {
    position: absolute;
    bottom: 0;
    left: 80px;
    transform: translateY(100%);
    color: ${colors.cinnabar};
    font-size: 1.2rem;
    font-weight: 600;
  }

`;

export default StyledInput;
