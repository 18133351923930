import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledTvRanking = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  gap: 50px;
  position: relative;
  padding-top: 5%;
  padding-bottom: 25%;
  background: white;

  .background-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    figure {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        background-size: contain;
      }
    }
  }

  .header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 120px;
    position: relative;
    background: ${colors.black};
    width: 65%;
    border-radius: 25px;
    padding: 30px 50px;

    .medaille-wrapper {
      position: absolute;
      left: 5%;
      top: 0;

      svg {
        width: 60px;

        & path {
          fill: ${colors.white} !important;
        }

        & polygon {
          fill: ${colors.white} !important;
        }
      }

    }

    span {
      color: ${colors.white};
      font-size: 2.5rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    .logo-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        position: absolute;
        width: 150px;
        height: 150px;
        z-index: 20;

      }

      &::before {
        z-index: 10;
        position: absolute;
        content: "";
        width: 180px;
        height: 180px;
        border-radius: 50%;
        background: ${colors.white};
      }
    }
  }


  .ranking-wrapper {
    position: relative;
    width: 70%;
    height: auto;
    min-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item {
      border-radius: 13px;
      width: 100%;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${colors.black};
      font-size: 1.8rem;
      flex-wrap: nowrap;

      &.dark {
        background: ${colors.athens};
      }

      span {
        font-family: 'DM', sans-serif;
        white-space: nowrap;
        width: 33%;
        font-size: 2rem;
        font-weight: 400;

        &:first-child {
          overflow: hidden;
          margin-right: 15px;
        }

        &:last-child {
          text-align: right;
        }
      }

      &.podium {
        span {
          font-size: 2.5rem;
          font-weight: 500;
        }
      }

    }
  }

  button {
    z-index: 1;
    text-transform: uppercase;
    font-size: 1.3rem;
    color: ${colors.white};
    background: ${colors.black};
    padding: 10px 30px;
    margin-top: 30px;
    border-radius: 13px;
    display: none;

    &.large {
      font-size: 1.7rem;
      padding: 15px 50px;

    }

    &:hover {
      background: ${colors.crusta};
    }
  }
}
`;

export default StyledTvRanking;
