import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Slugify from 'slugify';
import _ from 'lodash';
import { StyledLogin } from './style';
import Spinner from '../../../component/Spinner';
import Input from '../../../component/Input';
import Select from '../../../component/Select';
import { ReactComponent as Logo } from '../../../svg/mentionTB_logo.svg';

const countries = _.sortBy(_.uniqBy([
  {label: 'Claude Guyot', value: 'Claude Guyot'},
  {label: 'Claude Guyot', value: 'Claude Guyot'},
  {label: 'La Croix des Sarrasins', value: 'La Croix des Sarrasins'},
  {label: 'Gaspard Monge', value: 'Gaspard Monge'},
  {label: 'Jules Ferry', value: 'Jules Ferry'},
  {label: 'Jean Lacaille', value: 'Jean Lacaille'},
  {label: 'Georges Brassens', value: 'Georges Brassens'},
  {label: 'La Champagne', value: 'La Champagne'},
  {label: 'Fontaine des Ducs', value: 'Fontaine des Ducs'},
  {label: 'Edouard Herriot', value: 'Edouard Herriot'},
  {label: 'Le Chapitre', value: 'Le Chapitre'},
  {label: 'Camille Claudel', value: 'Camille Claudel'},
  {label: 'André Malraux', value: 'André Malraux'},
  {label: 'Carnot', value: 'Carnot'},
  {label: 'Jean-François Champollion', value: 'Jean-François Champollion'},
  {label: 'Clos de Pouilly', value: 'Clos de Pouilly'},
  {label: 'Gaston Bachelard', value: 'Gaston Bachelard'},
  {label: 'Gaston Roupnel', value: 'Gaston Roupnel'},
  {label: 'Henry Dunant', value: 'Henry Dunant'},
  {label: 'Jean-Philippe Rameau', value: 'Jean-Philippe Rameau'},
  {label: 'Le Parc', value: 'Le Parc'},
  {label: 'Les Lentillères', value: 'Les Lentillères'},
  {label: 'Marcelle Pardé', value: 'Marcelle Pardé'},
  {label: 'Montchapet', value: 'Montchapet'},
  {label: 'Les Hautes Pailles', value: 'Les Hautes Pailles'},
  {label: 'Henry Berger', value: 'Henry Berger'},
  {label: 'Albert Camus', value: 'Albert Camus'},
  {label: 'Paul Fort', value: 'Paul Fort'},
  {label: 'Emile Lepitre', value: 'Emile Lepitre'},
  {label: 'François de La Grange', value: 'François de La Grange'},
  {label: 'Roland Dorgelès', value: 'Roland Dorgelès'},
  {label: 'Marcel Aymé', value: 'Marcel Aymé'},
  {label: 'Arthur Rimbaud', value: 'Arthur Rimbaud'},
  {label: 'Louis Pasteur', value: 'Louis Pasteur'},
  {label: 'Lazare Carnot', value: 'Lazare Carnot'},
  {label: 'Félix Tisserand', value: 'Félix Tisserand'},
  {label: 'Isle de Saône', value: 'Isle de Saône'},
  {label: 'André Lallemand', value: 'André Lallemand'},
  {label: 'Jean Rostand', value: 'Jean Rostand'},
  {label: 'Henri Morat', value: 'Henri Morat'},
  {label: 'François Pompon', value: 'François Pompon'},
  {label: 'Champ Lumière', value: 'Champ Lumière'},
  {label: 'Christiane Perceret', value: 'Christiane Perceret'},
  {label: 'A et R Dinet', value: 'A et R Dinet'},
  {label: 'Jacques Mercusot', value: 'Jacques Mercusot'},
  {label: 'Boris Vian', value: 'Boris Vian'},
  {label: 'Alésia', value: 'Alésia'},
  {label: 'Docteur Kuhn', value: 'Docteur Kuhn'},
  {label: 'Saint Coeur', value: 'Saint Coeur'},
  {label: 'Saint Bernard', value: 'Saint Bernard'},
  {label: 'Saint Michel', value: 'Saint Michel'},
  {label: 'Saint Benigne', value: 'Saint Benigne'},
  {label: 'Saint François De Salles', value: 'Saint François De Salles'},
  {label: 'Saint Josheph - La Salle', value: 'Saint Josheph - La Salle'},
  {label: 'Ensemble Scolaire Saint-Augustin', value: 'Ensemble Scolaire Saint-Augustin'},
], 'value'), 'value', 'asc');
const Login = () => {
  const [isLoading, setIsLoading] = useState();
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
    control,
  } = useForm({});
  const navigate = useNavigate();
  const isValidInput = (chaine) => (/^[ .]+$/.test(chaine));
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const tmpUser = {...data};
      tmpUser.firstname = tmpUser.firstname?.replaceAll('.', '')?.replace(/[^\w\s]/gi, '').trim();
      tmpUser.lastname = tmpUser.lastname?.replaceAll('.', '')?.replace(/[^\w\s]/gi, '').trim();
      if (isValidInput(tmpUser?.lastname) || tmpUser?.lastname?.length === 0) {
        toast.error('Veuillez vérifier votre nom !');
        setIsLoading(false);
        return;
      }
      if (isValidInput(tmpUser?.firstname) || tmpUser?.firstname?.length === 0) {
        toast.error('Veuillez vérifier votre prénom !');
        setIsLoading(false);
        return;
      }
      tmpUser.id = Slugify(`${tmpUser.firstname}-${tmpUser.lastname}-${tmpUser.group}`)?.toLowerCase();
      localStorage.setItem('user', JSON.stringify(tmpUser));
      toast.success('Bienvenue sur TakeQR');
      navigate('/');
    } catch (error) {
      toast.error('Email ou mot de passe incorrecte !');
      console.error(error);
      reset();
      setIsLoading(false);
    }
  };
  return (
    <StyledLogin>
      <div className="background-wrapper">
        <figure>
          <img src="/mentionTB_fond-1.png" alt="bg"/>
        </figure>
      </div>
      <div className="logo-wrapper">
        <Logo/>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={'Nom'}
          required
          name={'lastname'}
          register={register}
          errors={errors}
          placeholder={'saisis ton nom'}
        />
        <Input
          label={'Prénom'}
          required
          name={'firstname'}
          register={register}
          errors={errors}
          placeholder={'saisis ton prénom'}
        />
        <Select
          label={'Collège'}
          required
          name={'group'}
          errors={errors}
          placeholder={'saisis ton collège'}
          options={countries}
          control={control}
        />
        {
          !isLoading ? (<button type="submit">Se connecter</button>) : (<Spinner/>)
        }
      </form>
    </StyledLogin>
  );
};

export default Login;
