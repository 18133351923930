import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledMyQrCode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  button {
    width: 250px;
    background: ${colors.cinnabar};
    color: ${colors.white};
    font-weight: 700;
    padding: 15px 30px;
    border-radius: 5px;
    &:hover {
      background: ${colors.crusta};
    }
  }
`;

export default StyledMyQrCode;
