import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import axios from '../../utils/axios';
import { StyledRanking } from './style';
import Spinner from '../../component/Spinner';
import { colors } from '../../utils/globals';
import { ReactComponent as Logo } from '../../svg/mentionTB_logo.svg';
import { ReactComponent as Medaille } from '../../svg/mentionTB_médaille.svg';

const Ranking = () => {
  const navigate = useNavigate();
  const user = useLoaderData();
  const [ranking, setRanking] = useState();
  useEffect(() => {
    const getRanking = async () => {
      const resp = await axios.get(`${process.env.REACT_APP_BACK_URL}/apps/ranking`);
      if (resp?.data?.length > 10) {
        setRanking((resp?.data?.slice(0, 10)));
      } else {
        setRanking(resp?.data);
      }
    };

    const intervalId = setInterval(() => {
      getRanking();
    }, 2000);
    document.body.style.zoom = '100%';
    window.scrollTo({top: 0, behavior: 'smooth'});
    return () => clearInterval(intervalId);
  }, []);

  const backToHome = () => {
    navigate('/');
  };
  return (
    <StyledRanking>
      <div className="background-wrapper">
        <figure>
          <img src="/mentionTB_fond-2.png" alt="bg"/>
        </figure>
      </div>
      <div className="logo-wrapper">
        <Logo/>
      </div>
      <div className="ranking-wrapper">
        {
          ranking ? (
            <>
              {
                ranking?.map((item, index) => (
                  <div key={item?.key} className={`item ${index < 3 ? 'podium' : ''}`}>
                    <span>
{`${item?.firstname?.charAt(0).toUpperCase() + item?.firstname.slice(1).toLowerCase()} ${item?.lastname?.charAt(0).toUpperCase() + item?.lastname?.slice(1).toLowerCase()}`}
                    </span>
                    <span>{`${item?.score} points`}</span>
                  </div>
                ))
              }
            </>
          ) : (<div className="ranking-wrapper_loading"><Spinner color={colors.manatee}/></div>)
        }
        {
          ranking && (
            <div className="ranking-wrapper_bg">
              <Medaille/>
            </div>
          )
        }
      </div>
      <button
        type="button"
        onClick={() => backToHome()}
      >
        Retour
      </button>
      <Outlet/>
    </StyledRanking>
  );
};

export default Ranking;
