import React, { useEffect, useState } from 'react';
import {
  useLoaderData, useNavigate, Outlet, useLocation, useParams,
} from 'react-router-dom';
import QRCode from 'qrcode.react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { StyledStand } from './style';
import Spinner from '../../component/Spinner';
import { colors } from '../../utils/globals';
//import allQuizz from '../../utils/allQuizz_loc.json';
import allQuizz from '../../utils/allQuizz.json';
import { ReactComponent as LogoSport } from '../../svg/mentionTB_picto-sport.svg';
import { ReactComponent as LogoParalympiques } from '../../svg/mentionTB_picto-paralympiques.svg';
import { ReactComponent as LogoMedailles } from '../../svg/mentionTB_picto-medailles.svg';
import { ReactComponent as LogoTourDeFrance } from '../../svg/mentionTB_picto-tourdefrance.svg';
import { ReactComponent as LogoSouvenir } from '../../svg/mentionTB_picto-photo.svg';

const Stand = () => {
  const navigate = useNavigate();
  const {search} = useLocation();
  const params = useParams();
  const user = useLoaderData();
  const [quizzIndex, setQuizzIndex] = useState(0);
  const [quizz, setQuizz] = useState({...allQuizz[params?.quizzId || params?.standId]});
  const [displayResult, setDisplayResult] = useState(false);
  const [grade, setGrade] = useState(0);
  const form = useForm({
    mode: 'onChange',
  });
  const {
    register, getValues, setValue, watch,
  } = form;
  const backToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    const visitStand = async () => {
      await axios.post(`${process.env.REACT_APP_BACK_URL}/apps/score`, {
        app: quizz.id,
        score: quizz.points,
        visited: true,
        ...user,
      });
      setGrade(quizz.points);
      setDisplayResult(true);
    };

    if (params?.standId) {
      visitStand();
    }
    const getScore = async () => {
      try {
        const resp = await axios.post(`${process.env.REACT_APP_BACK_URL}/apps/get-score`, {
          app: quizz?.id,
          ...user,
        });
        if (resp?.data?.completed || (resp?.data?.score?.toString() && resp?.data?.visited && params.standId) || (params?.quizzId && resp?.data?.score?.toString() && !resp?.data?.visited)) {
          setGrade(resp?.data?.score);
          setDisplayResult(true);
        }
      } catch (e) {
        console.log(e);
        toast.error("Cette page n'exsite pas !");
      }
    };
    if (params?.quizzId) {
      getScore();
    }
    document.body.style.zoom = '100%';
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  const updatePoints = (response) => {
    let points = 0;
    quizz?.questions[quizzIndex]?.responses?.forEach((resp) => {
      if (resp?.value === response) {
        points += resp?.points;
      }
    });
    setGrade((grade + points));
  };
  const nextQuestion = async () => {
    if (displayResult) {
      if (params?.quizzId) {
        await axios.post(`${process.env.REACT_APP_BACK_URL}/apps/score`, {
          app: quizz.id,
          score: grade,
          visit: false,
          ...user,
        });
      }
      navigate('/');
    }
    if ((quizzIndex + 1 < quizz?.questions?.length)) {
      updatePoints(getValues('response'));
      setValue('response', null);
      setQuizzIndex((quizzIndex + 1));
    } else {
      updatePoints(getValues('response'));
      setDisplayResult(true);
    }
  };

  const quizzIcon = () => (params?.quizzId === '1' || params?.standId === '1' ? <LogoSport/> : (
    params?.quizzId === '2' || params?.standId === '2' ? <LogoTourDeFrance/> : (
      params?.quizzId === '3' || params?.standId === '3' ? <LogoMedailles/> : (
        params?.quizzId === '4' || params?.standId === '4' ? <LogoParalympiques/> : (
          params?.quizzId === '5' || params?.standId === '5' ? <LogoSouvenir/> : (<span>*****</span>)
        )
      )
    )
  ));
  return (
    <StyledStand primaryColor={quizz?.primaryColor} secondaryColor={quizz?.secondaryColor}>
      <div className="background-wrapper">
        <figure>
          <img src="/mentionTB_fond-3.png" alt="bg"/>
        </figure>
      </div>
      {
        quizz ? (
          <>

            <div className="logo-wrapper">
              {
                quizzIcon()
              }
            </div>

            {
              quizz && displayResult && (
                <div className="result-wrapper">
                  <span>Tu as remporté</span>
                  <span>{`${grade} points `}</span>
                </div>
              )
            }

            {
              quizz && !displayResult && params?.quizzId && !params?.standId && (
                <div className="content">
                  <h1>{quizz?.questions[quizzIndex].question}</h1>
                  <form>
                    {
                      quizz?.questions[quizzIndex].responses?.map((response) => (
                        <div
                          key={response?.id}
                          className={`radio-wrapper ${watch('response') === response?.value ? 'active' : ''}`}
                          onClick={() => setValue('response', response?.value)}
                        >
                          <input
                            type="radio"
                            value={response.value}
                            {...register('response')}
                          />
                          <span>{response.value}</span>
                        </div>
                      ))
                    }
                  </form>
                </div>
              )
            }

            <div className="footer">
              <button
                type="button"
                className={`large ${params?.quizzId === '3' ? 'inverse' : ''}`}
                onClick={() => nextQuestion()}
              >
                {
                  !displayResult && !params?.standId ? 'valider' : 'terminer'
                }
              </button>
            </div>
          </>
        ) : <Spinner color={colors.blaze}/>
      }
      <Outlet/>
    </StyledStand>
  );
};

export default Stand;
