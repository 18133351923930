import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledQrGenerator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 10px;
  height: 100vh;
  background: ${(p) => p?.primaryColor || colors.white};
  gap: 50px;

  h1 {
    text-transform: uppercase;
    text-align: center;

    &.active {
      color: ${colors.white};
    }
  }

  .apps-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    overflow-y: auto;

    .item {
      box-shadow: 0 0 5px -1px ${colors.black};
      padding: 0 5px;
      cursor: pointer;
      height: 150px;
      width: 150px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: ${colors.white};
        font-weight: 700;
        text-align: center;
      }

      &:hover {
        opacity: .5;
      }

      &.ghost {
        box-shadow: unset;
        cursor: unset;

        &:hover {
          opacity: unset;
        }
      }
    }
  }

  .qr-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
  }

  .footer-wrapper {
    display: flex;
    gap: 10px;
    justify-content: center;

    button {
      font-family: 'DM', sans-serif;
      font-weight: 500;
      width: 250px;
      background: ${colors.cinnabar};
      color: ${colors.white};
      padding: 15px 30px;
      border-radius: 5px;

      &:hover {
        background: ${colors.crusta};
      }
    }
  }


`;

export default StyledQrGenerator;
