import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'react-qr-scanner';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import StyledQrReader from './styled';
import { breakpoint } from '../../utils/mixins';

const Index = () => {
  const [result, setResult] = useState();
  const navigate = useNavigate();

  const handleScan = (data = {}) => {
    if (_.get(data, 'text', false)) {
      if (data?.text?.includes('stand/') || data?.text?.includes('quizz/')) {
        setResult(data?.text);
      }
    }
  };

  const handleError = (error = {}) => {
    console.error(error);
  };
  const reset = () => {
    setResult(null);
  };

  useEffect(() => {
    if (result) {
      console.log(result);
      navigate(result);
    }
  }, [result]);

  return (
    <StyledQrReader>
      {
        !result && (
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{width: breakpoint.xxs ? '100%' : '50%'}}
            constraints={{
              video: {
                facingMode: {ideal: 'environment'},
              },
            }}
          />
        )
      }

      {/*<button type="button" onClick={() => reset()}>*/}
      {/*  fermer*/}
      {/*</button>*/}

    </StyledQrReader>
  );
};

export default Index;
