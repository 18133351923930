import React, { useEffect, useState } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import _ from 'lodash';
import axios from '../../utils/axios';
import { StyledNotFound } from './style';
import Spinner from '../../component/Spinner';
import { colors } from '../../utils/globals';

const NotFound = () => {
  const navigate = useNavigate();
  const error = useRouteError();
  const [canRender, setCanRender] = useState();
  useEffect(() => {
    if (_.get(error, 'status') === 401) {
      navigate('/login');
    }
  }, []);
  return (
    <StyledNotFound>
      {
        _.get(error, 'status') !== 401 ? (<h1>404</h1>) : <Spinner color={colors.manatee} />
      }

    </StyledNotFound>
  );
};

export default NotFound;
