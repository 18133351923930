import styled from 'styled-components';
import { colors } from '../../../utils/globals';

export const StyledLogin = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  gap: 5px;
  position: relative;
  padding-top: 10%;
  background: white;

  .background-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    figure {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        background-size: contain;
      }
    }
  }

  .logo-wrapper {
    svg {
      width: 120px;
    }
  }

  form {
    background: ${colors.white};
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 600px;
    padding: 30px 20px;
    border-radius: 2px;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;

      label {
        font-weight: 700;
        color: ${colors.white};
      }

      input {
        height: 50px;
        border-radius: 5px;
        border: 2px solid ${colors.white};
        padding: 0 10px;
      }

      p {
        &.error {
          color: ${colors.white};
        }
      }
    }

    button {
      z-index: 1;
      text-transform: uppercase;
      font-size: 1.3rem;
      color: ${colors.white};
      background: ${colors.black};
      padding: 10px 30px;
      margin-top: 30px;
      border-radius: 13px;

      &.large {
        font-size: 1.7rem;
        padding: 15px 50px;

      }

      &:hover {
        background: ${colors.crusta};
      }
    }
  }
`;

export default StyledLogin;
