import React from 'react';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { colors } from '../../utils/globals';

// @ts-ignore
const Spinner = ({ color }) => (
  <div>
    <PulseLoader color={color} />
  </div>
);

Spinner.propTypes = {
  color: PropTypes.string,
};

Spinner.defaultProps = {
  color: colors.white,
};

export default Spinner;
